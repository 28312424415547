.resourceHeader{
    padding-top: .3rem;
}

.sectionGap{
    margin-top: 3rem;
}

.recruitmentHeader{
    margin-top: .3rem;

}

.recruitContainer{
    background-color: #043032;
    padding: 2rem;
    margin-bottom: 3rem;
}

.recruitInner{
    background-color: #FFF6D4;
    padding: 2rem;
    display: flex;
    flex: 1;
    align-items: center;
}

.trainingHeader{
    padding-top: .3rem; 
}

.trainingSection{
    margin-bottom: 5rem;
}


@media (min-width: 768px) {
    .resourceHeader{
        padding-top: 3rem;
    }
    .sectionGap{
        margin-top: 4rem;
    }
    .recruitmentHeader{
        margin-top: 4rem;
        display: flex;
        align-items: center;
        gap: 3rem;
    }

    .trainingHeader{
        padding-top: 3rem; 
    }
    
}


@media (min-width: 1024px) {
    .resourceHeader{
        padding-top: 3rem;
        width: 60%;
    }
    .sectionGap{
        margin-top: 5rem;
    }
    .recruitContainer{
        padding: 4rem;
        margin-bottom: 0;
    }
    .recruitInner{
        padding: 3rem;
    }
    .recruitmentHeader{
        margin-top: 5rem;
        display: flex;
        align-items: center;
        gap: 6rem;
    }
    .recruitmentHeader > *{
        flex: 1 1;
    }

    .trainingHeader{
        padding-top: 3rem; 
    }
    .trainingSection{
        margin-bottom: 7rem;
    }

}