.padding{
    margin: 20px;
}

.started{
    background-color: black;
    border-radius: 2rem;
    padding: 2rem 1.1rem;

}

.imgSeg{
    display: none;
}

.spons{
    width: 40px;
    height: 30px;
    flex: 1;
}

.sponsGroup{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.startedMain{
    margin: 3rem 0 ;
}



@media (min-width: 768px) {
    .started{
        display: flex;
    padding: 2rem
    }
    .img{
        width: 80px;
        height: 80px;
    }
    .startedMain{
        margin: 3.5rem 0 ;
    }
}

@media (min-width: 1024px) {
    .started{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6rem;
        padding: 0;
    }
    .img{
        width: 450px;
        height: 450px;
    }
    .items{
        flex: 1;

    }
    .imgSeg{
        display: block;
    }
    .spons{
        width: 180px;
        height: 140px;
        flex: 1;
    }
    .sponsGroup{
        gap: 4rem;
    }
    .startedMain{
        margin: 5rem 0 ;
    }
}