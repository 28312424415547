.root{
    flex-basis: 100px;
    min-height: var(--input-height);
    border-radius: 8px;
    border: 1px solid var(--input-border);
    line-height: 140.62%;
    /* padding: 0px 15px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:25px;
    width: 100%;
    overflow: hidden;
  }
  .root input{
      font-size: var(--font-size-md);
      font-weight: 400;
      color:var(--primary-text);
      line-height: 140.62%;
      max-height: 100%;
      height: 100%;
      border: 0;
      flex: 1 0 0%;
      flex-basis: 30px;
      min-width: 30px;
      background-color: transparent;
  }
  .root input:focus{
      outline: none;
      background-color: transparent;
  }
  .root.errorInput{
      border: 1px solid var(--error-color);
  }
  .root.successInput{
      border: 1px solid var(--secondary-color);
  }
  .root.disabled{
      background-color: var(--social-background);
  }
  .fullWidth{
      width: 100%;
  }
  .root input:disabled{
      background-color: var(--disabled-background);
      color: var(--disabled-color);
  }
  .root:focus{
      outline:none;
      border: 1px solid var(--secondary-text);
  }

.KeyInput{
    min-width: 13px;
    flex: 1 0 0%;
    height: 67px;
    background: var(---secondary-background);
    border: 1px solid var(--social-background);
    border-radius: 5px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 140.62%;
    text-align: center;
    color: #0F172A;
}

.KeyInput:focus, .KeyInput:active, .KeyInput.active{
    border-color: var(--secondary-color) !important;
}

@media (max-width:600px) {
    .root input{
        font-size: var(--font-size-sm);
    }
}

/* @media (max-width:400px) {
    .root{
        max-width: 300px;
    }
} */

  