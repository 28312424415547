.main{
    padding-bottom: 18px;
}

/* .container {
   justify-content: center;
   align-items: center;

} */

.container {
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 18px;
    padding: 0 25px;
    height: 200px
}


@media (min-width: 768px) {
    .main {
        display: flex;
        gap: 18px;
    }
    .container{
        margin-bottom: 0;
        padding: 0 35px;
    }
}


@media (min-width: 1024px) {
    .main {
        display: flex;
        gap: 18px;
    }
    .container{
        margin-bottom: 0;
        padding: 0 25px;
    }
}

@media (min-width: 1044px) {
    .container{
        margin-bottom: 0;
        padding: 0 60px;
        height: 350px
    }
    .main {
        display: flex;
        gap: 18px;

    }
    .flex60 {
        flex: 0.6;
    }

    .flex40 {
        flex: 0.4;
    }
}