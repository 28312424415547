.footer{
    margin:  3rem 0 0;
    padding: 3rem 0rem;
}

.logo{
    width: 150px;
    padding-bottom: 2rem;
}

/* .center{
    text-align: center;
} */

.middle{
    padding: 3rem 0;
    text-align: center;
}

.socials{
    width: 25px;
    height: 25px;
    /* padding-left: 1rem; */
}

.input{
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    margin-bottom: 2rem;
}

.inputGroup{
    padding-top: 3rem;
}



@media (min-width: 768px) {
    .inputGroup{
        display: flex;
        gap: 3rem;
    }
}

@media (min-width: 1024px) {
    .inputGroup{
        display: flex;
        gap: 5rem;
    }
}