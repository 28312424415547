
.container {
    border-bottom: 1px solid rgba(51, 51, 51, 0.20);
    padding: 25px 0 ;
    cursor: pointer;
}


@media (min-width: 768px) {
    .container {
    padding: 30px 0 ;
    }
}


@media (min-width: 1024px) {
    .container {
        padding: 45px 0 ;
    }
}