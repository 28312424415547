* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    -webkit-tap-highlight-color: transparent;
}

:root{
    --primary-text: #1D2939;
    --secondary-text: #0B233F;
    --tertiary-text: #FFFFFF;
    --orange-text: #EE672F;
    --orange-bg: #F88C34;

    --font-size-xs: 14px;
    --font-size-sm: 16px;
    --font-size-md:18px;
    --font-size-md2: 20px;
    --font-size-lg:24px;
}

.main-padding{
  padding: 0 1.2rem;
}

.background{
  background-color: var(--secondary-background); 
}

.errorInput{
    border-color: var(--error-color);
    color:var(--error-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    display: block;
}

.orange-text{
  color: var(--orange-text);
  font-weight: 700;
  font-family: 'Medium';
  text-decoration: underline;
}


.main-side-bar{
    display: none;
}

.w-full{
    width: 100%;
}
.text-center{
    text-align: center;
}
.hover\:link:hover{
    text-decoration: underline;
    text-decoration-color: var(--secondary-color);
    cursor: pointer;
}
.row-start{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.space-between{
    justify-content: space-between;
}

.grey{
    color: yellow;
}

.modal-padding{
    padding: 28px 42px;
}
.adjust{
    padding: 20px 0px;
    font-size: 12px;
}


.flex_only{
  display: flex;
  align-items: center;
}

.orange{
  color: var(--orange-text);
  text-decoration: underline;
  padding-bottom: 0px;
  width: fit-content;
  font-family: Medium ;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0.4em;
}

.blog_scroll::-webkit-scrollbar{
  height: .1rem;
}

.carousel_scroll::-webkit-scrollbar{
  height: 0;
}

.baac_scroll::-webkit-scrollbar{
  height: 0;
}

::-webkit-scrollbar-track {
  background: #FFF6D6;

  margin-block: 0.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  background: #043032;

}

.genBg{
  background-color: #F5F5F5;
  // padding-bottom: 3rem;
}

.list p{
  // font-family: 'Medium';
  padding: 2rem 0 1rem;
  font-weight: 600;
  font-size: .9rem;
}

.list p:first-child{
  // font-family: 'Medium';
  // padding-top: 0
}

.list ol{
  list-style-position: inside;
}



.list ol li{
  padding-bottom: 1rem;
  color: #666666  ;
  font-size: .8rem;
  line-height: 1.2rem;
}

.list ol li:last-child{
  padding-bottom: 0rem;
}

img[alt="CONCEPT"]{
  width: 100%;
}





// ----------------------------------------------------

@media (min-width: 426px) and (max-width: 768px) {
    .testing {
        color: brown;
    }
    .flex_s{
      display: flex;
      justify-content: space-between;
    }
    .flex_between{
      @extend .flex_s
    }

    .input_flex{
      display: flex;
      gap: 2rem;
    }
    .main-padding{
      padding: 0 2rem;
    }
  
}

// ----------------------------------------------------

@media (min-width: 1024px) {
  .input_flex{
    display: flex;
    gap: 3rem;
  }

  .list p{
    font-size:1.1rem;
  }
  .list ol li{
    font-size: 1rem;
  }
    .adjust{
        padding: 20px;
    }
    .flex_ss{
      display: flex;
      justify-content: space-between;
    }

    .flex_between{
      @extend .flex_ss
    }
    .flexx{
    display: flex;
    justify-content: space-between;
    }
    .nextArticle_tagPadding__zmzkM .tags_tags__0yvZJ {
      background: linear-gradient(to right, #fff 60%, transparent 0) !important;

    }

    .articleList_tagPadding__jxiYT .tags_tags__0yvZJ {
      background: linear-gradient(to right, #fff 100%, transparent 0) !important;
    }

    .dirClass{
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
    }

   .dirCard{
      flex: 1 30%;
    }
    .main-padding{
      padding: 0 7rem;
    }
}
