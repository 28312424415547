.Text{
    line-height: 140.62%;
    /* font-weight: 400; */
}
.primary{
    color: #000;
}
.secondary{
    color: #666;
}
.tertiary{
    color: #043032;
}

.white{
    color: #fff;
}

.subsidiary{
    color: #333;
}

.cream{
    color: #FFF6D4;
}

.h1{
    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-bottom: 27px;
}

.h2{
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-bottom: 27px;
}

.h3{
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 22px;
}

.h4{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 6px;
}
.h5{
    font-family: 'Medium';
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    padding-bottom: 1.5rem;

}

.h6{
    font-family: 'Medium';
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    padding-bottom: 1.5rem;

}


/* @media (min-width: 426px) and (max-width: 768px) {
    .h1{
        font-size: 26px;
        line-height: 24px;
    }
    .h2{
        font-size: 24px;
    }
    .h3{
        font-size: 20px;
    }
    .h4{
        font-weight: 700;
        font-size: 17px;
    }

    .h6{
        font-size: 12px;
        line-height: 15px;
    }

} */

@media (min-width: 768px) {
    .h1{
        font-size: 42px;
    }
    .h2{
        font-size: 32px;
    }
    .h3{
        font-size: 28px;
    }
    .h4{
        font-size: 18px;
    }
}

@media (min-width: 1024px) {
    .h1{
        font-size: 52px;
    }
    .h2{
        font-size: 42px;
    }
    .h3{
        font-size: 38px;
    }
    .h4{
        font-size: 28px;
    }
    /*.h5{
        font-size: 18px;
    }
    .h6{
        font-size: 22px;
        line-height: 30px;
    } */
}