.container {
    padding-top: 3rem;
}

@media (min-width: 768px) {
    .container {
        width: 80%;
        margin: auto;
        padding-top: 3rem;
    }
}


@media (min-width: 1024px) {
    .container {
        width: 50%;
        margin: auto;
        padding-top: 4rem;
    }
}