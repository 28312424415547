
.button{
    margin-top: 3rem;
}

.input{
    width: 100%;
    padding: 0 1rem 1rem 0;
    font-size: 1rem;
    outline: 0;
    border: none;
}

.inputContainer{
    border-bottom: 1px rgba(51, 51, 51, 0.20) solid;
    margin-bottom: 2rem;
}

.upload{
    background-color: #e0f5f2;
    text-align: center;
    padding: 2rem;
    display: "inline-block";
    margin-top: 3rem;
    cursor: pointer;
}

.mobile{
    display: block
}

.desktop{
    display: none
}

.uploadContainer{
    margin-top: 4rem
}


@media (min-width: 768px) {
    .button{
        margin-top: 3rem;
    }
    .input{
        font-size: 1rem;
    }
    .inputContainer{
        margin-bottom: 3rem;
    }
}


@media (min-width: 1024px) {
    .button{
        margin-top: 0rem;
    }
    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .input{
        font-size: 1.2rem;
    }
    .inputContainer{
        margin-bottom: 4rem;
    }
    .mobile{
        display: none
    }
    
    .desktop{
        display: block;
    }
    .uploadContainer{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10rem;
    }
    .upload{
        margin-top: 0;
    }
    .flex60 {
        flex: 0.6;
    }

    .flex40 {
        flex: 0.4;
    }
}