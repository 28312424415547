* {
  padding: 0;
  margin: 0;
    font-family: "Montserrat", sans-serif;
  // font-family: 'Work Sans', sans-serif;}
}

// .active {
//   border-bottom: 1px solid #ea2424;
// }

$white: #000;

.link {
  display: none;
}

.menu {
  display: block;
}

header {
  & .main-header {
    // background-color: blue;
    display: flex;
    justify-content: space-between;
    padding: 2rem 1rem;
  }
  & img[alt="LOGO"] {
    width: 60px;
  }
  & img[alt="MENU"] {
    width: 30px;
  }
}

.toggle {
  display: flex;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 80px;
  right: -100%;
  opacity: 0;
  transition: all 1s ease;
  flex-direction: column;
  list-style-type: none;
  grid-gap: 0px;
  font-size: 20px;
  z-index: 16;
  background: #fff;
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.2);

  top: 0;
  & p {
    font-size: 1rem;
  }
}

.toggle.active {
  background: rgb(4, 48, 50);
  right: 0;
  opacity: 1;
  transition: all 1s ease;
  z-index: 16;
  align-content: center;
  padding-left: 0px;
  padding: 1rem 1.5rem;
  top: 0;

  & img{
    filter: invert(80%) sepia(10%) saturate(100%) hue-rotate(100deg);

  }
}

.menu {
  &-list {
    margin-top: 2rem;
    & a {
      text-decoration: none;
      // border-bottom: none;
      color: #1f1f1f;
    }
  }
}

.solution {
  &-mobile {
    margin-left: 0.8rem;
    & p{
        padding-bottom: .5rem;
    }
  }
}

img[alt="dropdown"] {
  width: 25px;
  margin-top: 5px;
}

.solution-dropdown {
  display: flex;
  align-items: center;
}

.menu-list {
  & a {
    font-size: 1.2rem;
    color: #FFF6D4;
    font-weight: 500;
  }
  & > div{
    padding-bottom: 2rem;
  }
}

.solution-mobile{
    & p{
        padding-bottom: 1rem;
    }
}

// ====================================================================

@media (min-width: 768px) and (max-width: 1023px) {
  header img[alt="LOGO"] {
    width: 80px;
  }
  .toggle {
    width: 30%;
  }
  header {
    & .main-header {
      padding: 1rem 2rem;
    }
  }
}

// ===================================================================

@media (min-width: 1024px) {
  header {
    padding: 0 6rem;
    a {
      color: black;
      text-decoration: none;
    }
    & .main-header {
      // background-color: blue;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    & li {
      list-style: none;
      color: $white;
      font-size: 16px;
    }
    & .header li {
      color: #000;
    }
    & .main-links {
      display: flex;
      align-items: center;
      & a {
        margin-right: 2rem;
        &:last-child {
          padding-right: 0;
        }
      }
      & ul {
        display: flex;
        align-items: center;
        & li {
          font-size: 12px;
          padding-right: 0.6rem;
        }
      }
    }
    & img[alt="LOGO"] {
      width: 60%;
    }

    & img[alt="FirstBank"] {
      // width: 60%;
    }
  }
  .menu {
    display: none;
  }
  .link {
    display: block;
  }

  .section {
    padding: 0 8rem;
  }
  header img[alt="LOGO"] {
    width: 80px;
  }
  .subRoute {
    background-color: #ffffff;
    position: absolute;
    // top: 10;
    padding-top: 30px;
    // padding-bottom: 30px;
    margin-top: 0px;
    width: 170px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a shadow with blur and color */
    & a li:not(:last-child) {
      //   margin-bottom: px;
    }
    & > a li {
      font-size: 0.9rem;
      //   padding-left: 24px;
      //   padding-right: 24px;
      padding: 10px 15px;
    }
    & a li:hover {
      background-color: #fff6d6;
    }
  }

  .dropdown {
    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .solution-header {
    & .subRoute {
      display: none;
    }
    &:hover .subRoute {
      display: block;
    }
  }
}
