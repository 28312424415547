.Text{
    /* line-height: 140.62%; */
    /* font-weight: 400; */
}
.primary{
    color: #000;
}
.secondary{
    color: #666;
}
.tertiary{
    color: #043032;
}

.white{
    color: #fff;
}

.subsidiary{
    color: #333;
}

.cream{
    color: #FFF6D4;
}

.lg{
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5; /* 150% */
}

.md{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5; /* 150% */
}

.sm{
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5; /* 150% */
}
.xs{
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5; /* 150% */
}




@media (min-width: 768px) {
    .lg{
        font-size: 20px;
        /* line-height: 24px; */
    }
    .md{
        font-size: 18px;
        /* line-height: 28px; */
    }
    .sm{
        font-size: 16px;
        /* line-height: 28px; */
    }
    .Xs{
        font-size: 14px;
        /* line-height: 28px; */
    }
}

@media (min-width: 1024px) {
    .lg{
        font-size: 24px;
        /* line-height: 28px; */
    }
    .md{
        font-size: 20px;
        /* line-height: 28px; */
    }
    .sm{
        font-size: 18px;
        /* line-height: 28px; */
    }
    .xs{
        font-size: 16px;
        /* line-height: 28px; */
    }
}

