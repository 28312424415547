.button{
    font-weight: 600;
}

.container {
    padding: 2px 15px
}


@media (min-width: 768px) {
    .container {
        padding: 7px 38px
    }
}


@media (min-width: 1024px) {
    .container {
        padding: 7px 43px;
    }
}