.contactHeader{
    padding-top: .3rem; 
}

@media (min-width: 768px) {
    .contactHeader{
        padding-top: 3rem; 
    }
}

@media (min-width: 1024px) {
    .contactHeader{
        padding-top: 3rem; 
        width: 60%;
        margin: auto;
    }
}