.img {
    width: 100%; /* Make the image fill the width of its container */
    height: auto; /* Maintain the aspect ratio of the image */
    /* max-width: 100%; Ensure the image does not exceed its original size */
    margin-top: 2rem;
}

.button{
    /* margin-top: 4rem; */
    display: flex;
    gap: 10px;
}

@media (min-width: 768px) {

}


@media (min-width: 1024px) {
    .container{
        display: flex;
        align-items: center;
        gap: 70px;
    }
    .flexItem{
        flex: 1;
    }
    .flex60 {
        flex: 0.6;
    }

    .flex40 {
        flex: 0.4;
    }
    .button{
        margin-top: 4rem;
        gap: 30px;
    }
}